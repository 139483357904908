import React from "react"
import { Link } from "gatsby"
import { Twitter, Github } from "@styled-icons/simple-icons"

import { rhythm, scale } from "../utils/typography"
import ElsewhereLink from "./elsewhere-link"
import { Helmet } from "react-helmet"

const headerColor = "#b95555"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header
  let iconSizeInPx

  if (location.pathname === rootPath) {
    iconSizeInPx = 32
    header = (
      <h1
        style={{
          ...scale(1),
          marginBottom: rhythm(1.5),
          marginTop: 0,
          color: headerColor,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
            textDecoration: `none`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    iconSizeInPx = 24
    header = (
      <h3 style={{ color: headerColor }}>
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(31.5),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <Helmet>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest"></link>
        {
          "<!-- Twemoji graphics made by Twitter and other contributors, licensed under CC-BY 4.0: https://creativecommons.org/licenses/by/4.0/ -->"
        }
      </Helmet>
      <header
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {header}
        <div style={{ padding: 4 }}>
          <ElsewhereLink href="https://twitter.com/sepet" title="twitter link">
            <Twitter width={iconSizeInPx} height={iconSizeInPx} />
          </ElsewhereLink>
          <ElsewhereLink href="https://github.com/sepeth" title="github link">
            <Github width={iconSizeInPx} height={iconSizeInPx} />
          </ElsewhereLink>
        </div>
      </header>
      <main>{children}</main>
    </div>
  )
}

export default Layout
