import React from "react"
import { elsewhereLink } from "./elsewhere-link.module.css"

export default function ElsewhereLink({ children, ...props }) {
  return (
    <a className={elsewhereLink} {...props}>
      {children}
    </a>
  )
}
