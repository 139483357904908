import Typography from "typography"
import nordTheme from "typography-theme-nord"

const typography = new Typography({
  ...nordTheme,
  googleFonts: [],
  bodyFontFamily: ["Helvetica", "sans-serif"],
  headerFontFamily: ["Futura", "sans-serif"],
  headerWeight: 400,
  overrideThemeStyles: ({ rhythm }, options) => ({
    h2: {
      marginBottom: rhythm(1 / 2),
      marginTop: rhythm(1.5),
    },
    a: {
      color: '#5478A3'  // '#4E719C'
    }
  })
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
